<template>
  <b-card-code :title="`Insersión de Data Formulario Malaria`">
    <h5>Visitas</h5>
    <b-row>
      <b-col>
        <b-form-group label="Casas">
          <b-form-input v-model="item.Casas"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Cent. Educ.">
          <b-form-input v-model="item.Cent_Educ"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Aut. Locales">
          <b-form-input v-model="item.Aut_Locales"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Inst. Salud">
          <b-form-input v-model="item.Inst_Salud"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <hr class="mb-1" id="mainView">

    <h5>Muestras Tomadas</h5>
    <b-row>
      <b-col>
        <b-form-group label="PDR">
          <b-form-input v-model="item.PDR"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="PDR Positivos">
          <b-form-input v-model="item.PDR_Positivos"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="GG">
          <b-form-input v-model="item.GG"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="GG Positivo">
          <b-form-input v-model="item.GG_Positivo"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Total positivas">
          <b-form-input v-model="item.Total_positivas"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <hr class="mb-1" id="mainView">

    <h5>Positivos</h5>
    <b-row>
      <b-col>
        <b-form-group label="Tratamientos">
          <b-form-input v-model="item.Tratamientos"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Investigados">
          <b-form-input v-model="item.Investigados"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Vivax">
          <b-form-input v-model="item.Vivax"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Falciparun">
          <b-form-input v-model="item.Falciparun"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <hr class="mb-1" id="mainView">

    <h5>Intervenciones</h5>
    <b-row>
      <b-col class="offset-3">
        <h6>Neb</h6>
      </b-col>
      <b-col class="offset-1">
        <h6>Químico</h6>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Roc">
          <b-form-input v-model="item.RocIntervenciones"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col class="offset-1">
        <b-form-group label="Casas">
          <b-form-input v-model="item.Casas_1"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Abrigos">
          <b-form-input v-model="item.Abrigos"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col class="offset-1">
        <b-form-group label="Roc / Kg">
          <b-form-input v-model="item.Roc"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Neb / Lt">
          <b-form-input v-model="item.Neb"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <hr class="mb-1" id="mainView">

    <h5>Colecta de Adultos</h5>
    <b-row>
      <b-col md="3">
        <b-form-group label="N° de mosquitos">
          <b-form-input v-model="item.No_de_mosquitos"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <hr class="mb-1" id="mainView">

    <h5>Control Larvarios - Nº de Criaderos Anophelinos</h5>
    <b-row>
      <b-col>
        <b-form-group label="Enc.">
          <b-form-input v-model="item.Enc"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Trat.">
          <b-form-input v-model="item.Trat"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Larv. Gast/lbs">
          <b-form-input v-model="item.Larv_Gast_lbs"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Eval.">
          <b-form-input v-model="item.Eval"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
        >
          Guardar
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
        >
          Cancelar
        </b-button>
      </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BContainer, BFormGroup, BFormInput, BFormSelect, BRow, BCol,
  BButton, BDropdown, BDropdownItem, BFormDatepicker, BToast, BSpinner
} from 'bootstrap-vue'
import BCardCode from "@core/components/b-card-code";


export default {
  components: {
    BAvatar,
    BBadge,
    BPagination,
    BContainer,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BToast,
    BSpinner,
    BCardCode,
  },

  data() {
    return {
      item: {
        Casas: "",
        Cent_Educ: "",
        Aut_Locales: "",
        Inst_Salud: "",
        PDR: "",
        PDR_Positivos: "",
        GG: "",
        GG_Positivo: "",
        Total_positivas: "",
        Tratamientos: "",
        Investigados: "",
        Vivax: "",
        Falciparun: "",
        RocIntervenciones: "",
        Casas_1: "",
        Abrigos: "",
        Roc: "",
        Neb: "",
        No_de_mosquitos: "",
        Enc: "",
        Trat: "",
        Larv_Gast_lbs: "",
        Eval: ""
      }
    };
  }
};
</script>
